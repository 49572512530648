<template>
	<div>
		<CRow>
			<loading-screen v-if="isLoading" :information-text="textLoading" />
			<CCol sm="12" class="center-text">
				<p class="h6 justify-text">
					A continuaci&oacute;n, podr&aacute;s visualizar un
					gr&aacute;fico con informaci&oacute;n sobre la cantidad de
					solicitudes en cada estatus.
				</p>
				<p class="h6 justify-text">
					<span class="h6 badge badge-info">Nota: </span>
					Si no visualizas un color correspondiente a un estatus en el
					gr&aacute;fico significa que no existen solicitudes.
				</p>
			</CCol>
		</CRow>
		<CChartDoughnut :datasets="defaultDatasets" :labels="labelItemsChart" />
		<!-- <br />
		<CRow>
			<CCol sm="12" class="center-text">
				<p class="h6 justify-text">
					Cantidad de solicitudes por estatus
				</p>
				<p
					class="h6 justify-text"
					v-for="(value, key) in cant"
					:key="key"
				>
					<b>{{ key }}</b
					>: {{ value }}
				</p>
			</CCol>
		</CRow> -->
	</div>
</template>

<script>
	import { CChartDoughnut } from '@coreui/vue-chartjs';
	import functionsReports from '../../functions/functionsReports';

	export default {
		name: 'StatusDonutChart',
		components: { CChartDoughnut },
		data() {
			return {
				dataChart: null,
				labelItemsChart: [
					'Por Autorizar',
					'Autorizadas',
					'Autorización Rechazada',
					'Autorización Vencida',
					'Autorización Eliminada',
					'Data Eliminada por Cliente',
					'Error al Refrescar el Token',
				],
				cant: {},
				isLoading: true,
				textLoading: 'Cargando el gr&aacute;fico, por favor espera',
			};
		},
		created() {
			functionsReports
				.getChartHome()
				.then((response) => {
					this.dataChart = Object.values(response[0]);
					this.cant = {
						'Por Autorizar': response[0].toAuthorize,
						Autorizadas: response[0].authorize,
						'Autorización Rechazada':
							response[0].authorizationRejected,
						'Autorización Vencida':
							response[0].authorizationExpired,
						'Autorización Eliminada':
							response[0].authorizationDeleted,
						'Data Eliminada por Cliente':
							response[0].customerDeletedData,
						'Error al Refrescar el Token':
							response[0].RefreshTokenError,
					};
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		computed: {
			defaultDatasets() {
				return [
					{
						backgroundColor: [
							'#F9B115',
							'#41B883',
							'#DD1B16',
							'#e55353',
							'#636f83',
							'#73adbf',
							'#931322',
						],
						data: this.dataChart,
					},
				];
			},
		},
	};
</script>
