<template>
	<div>
		<CCard>
			<CCardHeader>
				<h4 class="center-text">
					¡Bienvenido a InfoFeed {{ this.userInfo.name }}!
				</h4></CCardHeader
			>
			<CCardBody>
				<CRow>
					<CCol sm="12" class="center-text">
						<p class="h6 justify-text">
							A trav&eacute;s de esta aplicaci&oacute;n
							podr&aacute;s enviar un e-mail al cliente para
							solicitarle la autorizaci&oacute;n y mostrar su Feed
							de
							<b>Instagram</b> en el site de <b>Infoguia</b>.
						</p>
						<p class="h6 justify-text">
							Para desplazarte por los diferentes m&oacute;dulos
							de la aplicaci&oacute;n, puedes usar el men&uacute;
							lateral izquierdo y en la barra superior
							tendr&aacute;s algunos accesos r&aacute;pidos.
						</p>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
		<CCard v-if="showChart">
			<CCardHeader> <h5>Estatus de Solicitudes</h5></CCardHeader>
			<CCardBody>
				<status-donut-chart />
			</CCardBody>
		</CCard>
	</div>
</template>
<script>
	import StatusDonutChart from '../components/charts/statusDonutChart.vue';
	export default {
		components: { StatusDonutChart },
		/** Componente que sirve para el dashboard(home) del sistema cuando el
		 * usuario inicia sesión en el sistema */
		name: 'Dashboard',
		title: 'Info Feed | Infoguia',
		data() {
			return {
				imgInfofriend1: process.env.VUE_APP_WELCOME_INFOFRIEND1,
				imgInfoFriend2: process.env.VUE_APP_WELCOME_INFOFRIEND2,
				userInfo: {},
				dataChart: null,
				//isMobile: navigator.userAgentData.mobile,
				showChart: false,
			};
		},
		beforeCreate: function() {},
		created() {
			this.userInfo = JSON.parse(sessionStorage.getItem('userData'));
			this.widthHandler();
		},
		destroyed() {
			window.removeEventListener('resize', this.widthHandler);
		},
		methods: {
			widthHandler(e) {
				let minWidth = 560;
				if (document.documentElement.clientWidth >= minWidth) {
					this.showChart = true;
				} else {
					this.showChart = false;
				}
			},
		},
		mounted() {
			window.addEventListener('resize', this.widthHandler);
		},
	};
</script>
